import React, { useState } from "react";

const Modal = (props) => {
  const [modalitem, setmodalitem] = useState();

  return (
    <div className="modal">
      {props.error && (
        <div>
          <div>{props.errordisplay}</div>
        </div>
      )}
      {props.loading && <div>{/* loading spinner */}</div>}
      {/* square div */}
      <div> We'll get back to you shortly. </div>
      <button className="modal-button">Thank You !</button>
    </div>
  );
};

export default Modal;
