import React, { useCallback, useEffect, useState } from "react";

import axios from "axios";

import painting from "../../photos/maryandlambWilliamBouguereau.jpg";
import { useForm } from "react-hook-form";
import Modal from "../../helpers/Modal";

const Chapel = () => {
  // step 1 post submit form

  // post submit form //

  const { register, handleSubmit } = useForm({});

  const [modalitem, setmodalitem] = useState();

  // use css to blur form
  const [formstate, setformstate] = useState();
  const [formstate2, setformstate2] = useState();

  const [axiosLoading, setAxiosLoading] = useState();

  // get function for ip requests
  const checkform = useCallback(async () => {
    try {
      const axiosformcheck = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/quote/quotelistener`
      );

      //console.log(axiosformcheck.data);

      if (!axiosformcheck.data.quotecheck) {
        // console.log("go");
        //console.log()
      } else if (axiosformcheck.data.quotecheck) {
        setformstate("form-blur");
        setformstate2(true);
        //console.log("blur");
      }
    } catch (error) {}
  }, []);

  useEffect(() => {
    // get function for ip requests

    checkform();
  }, [checkform]);

  const quoteSubmit = async (data) => {
    try {
      // const quote = {
      //   firstName: data.firstName,
      //   lastName: data.lastName,
      //   phoneNumber: data.phoneNumber,
      //   email: data.email,
      //   city: data.city,
      //   state: data.state,
      // };

      //setAxiosLoading(true);
      const response = axios.post(
        // Endpoint to send files

        `${process.env.REACT_APP_BACKEND_URL}/quote/requestQuote`,

        data,

        {
          "Content-Type": "application/json",
        }
        // headers: {
        //   // Add any auth token here
        //   authorization: "your token comes here",
        // },
        // Attaching the form data
      );

      // console.log(data);
      // console.log(response);
      //setAxiosLoading(false);
      // console.log("check 1");
      if (response) {
        const checkform = async () => {
          try {
            const axiosformcheck = await axios.get(
              `${process.env.REACT_APP_BACKEND_URL}/quote/quotelistener`
            );

            //console.log(axiosformcheck.data);

            setformstate("form-blur");
            setformstate2("form-thankyou");
            //console.log("blur");
          } catch (error) {
            //console.error("Modal time", error);
          }
        };

        checkform();
      }
    } catch (error) {
      //console.error("Modal time", error);
      // use the error to produce a modal
    }
    // if (axiosLoading === false) {

    // }

    // axios get for modal // form
  };

  const [myTime, setMyTime] = useState("1pm");

  const handleChange = (event) => {
    setMyTime(event.target.value);
  };

  // post submit form //
  // step 2 server get , " ip submitted within certain date " state to use ip

  return (
    <div className="chapel">
      {modalitem && <Modal />}
      <div className="chapel-center">
        <div className="blockOne">
          <div className="chapel-title">GET A QUOTE</div>
          <div className="details">
            <div>Ages 20-85</div>
            <hr className="dividerhr" />
            <div>Healthy Applicants instantly accepted</div>
            <hr className="dividerhr" />
            <div className="details-portraitcontainer">
              <img
                className="details-painting"
                src={painting}
                alt="Mary and Jesus"
              />
            </div>
          </div>
        </div>
        <div className="blockTwo">
          <div className="chapel-title">LIFE INSURANCE QUOTE</div>
          {formstate2 && <div className="form-thankyou">Thank You!</div>}
          <div className={`form ${formstate}`}>
            <div className="form-structure">
              <form onSubmit={handleSubmit(quoteSubmit)}>
                {" "}
                <div>
                  <div className="form-info1">
                    <div>First Name</div>
                    <input {...register("firstName")} />
                  </div>
                  <div className="form-info1">
                    <div>Last Name</div>
                    <input {...register("lastName")} />
                  </div>
                  <br />
                  <div className="form-info1">
                    <div>email</div>
                    <input {...register("email")} />
                  </div>
                  <div className="form-info1">
                    <div>phone number</div>
                    <input {...register("phoneNumber")} />
                  </div>
                </div>
                <div>
                  <div>smoker</div>
                  <input {...register("smoker")} />
                </div>
                <div>
                  Major health issues ( heart attack, cirrhosis, kidney failure,
                  lung disease, ect. )
                </div>
                <input {...register("health")} />
                <div>
                  <div className="form-info1">
                    <div>City</div>
                    <input {...register("city")} />
                  </div>
                  <div className="form-info1">
                    <div>State</div>
                    <input {...register("state")} />
                  </div>
                </div>
                <div></div>
                <br />
                <select
                  {...register("correspondanceday1")}
                  value={myTime}
                  onChange={handleChange}
                >
                  <option value="Saturday">Saturday</option>
                  <option value="Monday">Monday</option>
                  <option value="Tuesday">Tuesday</option>
                  <option value="Wednesday">Wednesday</option>
                  <option value="Thursday">Thursday</option>
                  <option value="Friday">Friday</option>
                </select>{" "}
                <select
                  {...register("correspondance1")}
                  value={myTime}
                  onChange={handleChange}
                >
                  <option value="8 a.m.">8 a.m.</option>
                  <option value="9 a.m.">9 a.m.</option>
                  <option value="10 a.m.">10 a.m.</option>
                  <option value="11 a.m.">11 a.m.</option>
                  <option value="12 a.m.">12 a.m.</option>
                  <option value="1 p.m.">1 p.m.</option>
                  <option value="2 p.m.">2 p.m.</option>
                  <option value="3 p.m.">3 p.m.</option>
                  <option value="4 p.m.">4 p.m.</option>
                  <option value="5 p.m.">5 p.m.</option>
                  <option value="6 p.m.">6 p.m.</option>
                  <option value="7 p.m.">7 p.m.</option>
                </select>
                {" - "}
                <select
                  {...register("correspondance2")}
                  value={myTime}
                  onChange={handleChange}
                >
                  <option value="8 a.m.">8 a.m.</option>
                  <option value="9 a.m.">9 a.m.</option>
                  <option value="10 a.m.">10 a.m.</option>
                  <option value="11 a.m.">11 a.m.</option>
                  <option value="12 a.m.">12 a.m.</option>
                  <option value="1 p.m.">1 p.m.</option>
                  <option value="2 p.m.">2 p.m.</option>
                  <option value="3 p.m.">3 p.m.</option>
                  <option value="4 p.m.">4 p.m.</option>
                  <option value="5 p.m.">5 p.m.</option>
                  <option value="6 p.m.">6 p.m.</option>
                  <option value="7 p.m.">7 p.m.</option>
                </select>
                <div></div>
                <br />
                <select
                  {...register("correspondanceday2")}
                  value={myTime}
                  onChange={handleChange}
                >
                  <option value="Saturday">Saturday</option>
                  <option value="Monday">Monday</option>
                  <option value="Tuesday">Tuesday</option>
                  <option value="Wednesday">Wednesday</option>
                  <option value="Thursday">Thursday</option>
                  <option value="Friday">Friday</option>
                </select>{" "}
                <select
                  {...register("correspondance3")}
                  value={myTime}
                  onChange={handleChange}
                >
                  <option value="8 a.m.">8 a.m.</option>
                  <option value="9 a.m.">9 a.m.</option>
                  <option value="10 a.m.">10 a.m.</option>
                  <option value="11 a.m.">11 a.m.</option>
                  <option value="12 a.m.">12 a.m.</option>
                  <option value="1 p.m.">1 p.m.</option>
                  <option value="2 p.m.">2 p.m.</option>
                  <option value="3 p.m.">3 p.m.</option>
                  <option value="4 p.m.">4 p.m.</option>
                  <option value="5 p.m.">5 p.m.</option>
                  <option value="6 p.m.">6 p.m.</option>
                  <option value="7 p.m.">7 p.m.</option>
                </select>
                {" - "}
                <select
                  {...register("correspondance4")}
                  value={myTime}
                  onChange={handleChange}
                >
                  <option value="8 a.m.">8 a.m.</option>
                  <option value="9 a.m.">9 a.m.</option>
                  <option value="10 a.m.">10 a.m.</option>
                  <option value="11 a.m.">11 a.m.</option>
                  <option value="12 a.m.">12 a.m.</option>
                  <option value="1 p.m.">1 p.m.</option>
                  <option value="2 p.m.">2 p.m.</option>
                  <option value="3 p.m.">3 p.m.</option>
                  <option value="4 p.m.">4 p.m.</option>
                  <option value="5 p.m.">5 p.m.</option>
                  <option value="6 p.m.">6 p.m.</option>
                  <option value="7 p.m.">7 p.m.</option>
                </select>
                <button className="form-submitBttn">submit</button>
              </form>
              {/* modal  */}
            </div>
          </div>

          <div className="form-border"></div>
        </div>
      </div>
    </div>
  );
};

export default Chapel;
