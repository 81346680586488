import React from "react";
import Courtyard from "./temple/courtyard/Courtyard";

import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Routes,
} from "react-router-dom";

import "./css/style.css";
import Chapel from "./temple/chapel/Chapel";

const App = () => {
  let theRoutes = (
    <Routes>
      <Route path="/" element={<Courtyard />} />
      <Route path="/quote" element={<Chapel />} />
    </Routes>
  );
  return (
    <div>
      <Router>{theRoutes}</Router>
    </div>
  );
};

export default App;
